import 'jquery-mask-plugin/dist/jquery.mask';
import Typed from 'typed.js';

jQuery(function($) {
  const formularioLogin = $('#login-modal form');

  if (formularioLogin.length === 0) return;

  const camposLogin = formularioLogin.find('input,button[type=submit]');
  const submitLogin = formularioLogin.find('button[type=submit]');
  const erroLogin = $('#login-erro');
  const campoSenha = $('#user_password');
  const campoCpf = $('#user_cpf');
  const botaoEntrar = $('#open-login');

  formularioLogin.on('submit', realizaLogin);
  abreModalDeLoginSeNecessario();
  campoCpf.mask('000.000.000-00');

  new Typed('.typed-head', {
    stringsElement: '#typed-strings-1',
    typeSpeed: 30,
    startDelay: 0,
    showCursor: false,
    fadeOut: false,
    loop: false,
  });

  new Typed('.typed-text', {
    stringsElement: '#typed-strings-2',
    typeSpeed: 50,
    backSpeed: 50,
    backDelay: 3000,
    startDelay: 3000,
    fadeOut: true,
    loop: true
  });

  function abreModalDeLoginSeNecessario() {
    if (location.hash == '#login' && botaoEntrar.length) {
      $('#login-modal')
        .modal('show')
        .on('shown.bs.modal', function() {
          if (campoCpf.val()) {
            campoSenha.select();
          } else {
            campoCpf.select();
          }
        });
    }
  }

  function realizaLogin(ev) {
    ev.preventDefault();
    if (processandoLogin()) return;

    var form = $(this);
    $.post(form.attr('action'), form.serialize(), trataRespostaDeLogin);
    iniciaLoginVisualmente();
  }

  function trataRespostaDeLogin(dados) {
    if (dados.success) {
      location.href = dados.redirectTo;
    } else {
      encerraLoginVisualmente();
      exibeErro();
      campoSenha.select();
    }
  }

  function exibeErro() {
    erroLogin.removeClass('d-none');
  }

  function escondeErro() {
    erroLogin.addClass('d-none');
  }

  function iniciaLoginVisualmente() {
    travaFormulario();
    marcaSubmitComoCarregando();
    escondeErro();
  }

  function encerraLoginVisualmente() {
    destravaFormulario();
    desmarcaSubmitComoCarregando();
  }

  function marcaSubmitComoCarregando() {
    submitLogin.find('span').text('Entrando...');
  }

  function desmarcaSubmitComoCarregando() {
    var span = submitLogin.find('span');
    span.text(span.data('labelOriginal'));
  }

  function travaFormulario() {
    formularioLogin.data('processando', true);
    camposLogin.attr('disabled', true);
  }

  function destravaFormulario() {
    formularioLogin.data('processando', false);
    camposLogin.removeAttr('disabled');
  }

  function processandoLogin() {
    return formularioLogin.data('processando');
  }
});
