import 'jquery-mask-plugin/dist/jquery.mask';

jQuery(function($) {
  const formCadastro = $('#cadastro-modal form');

  if (formCadastro.length === 0) return;

  const camposCadastro = formCadastro.find('input,button[type=submit]');
  const submitCadastro = formCadastro.find('button[type=submit]');
  const errosCampos = formCadastro.find('.invalid-feedback');
  const campoCpf = formCadastro.find('.cpf');
  const msgSucesso = $('#cadastro-sucesso');

  formCadastro
    .on('submit', realizaCadastro)
    .on('keydown', '.form-control', removeErro);
  campoCpf.mask('000.000.000-00');
  $('.phone_with_ddd').mask('(00) 00000-0000');

  function realizaCadastro(ev) {
    ev.preventDefault();
    if (processandoCadastro()) return;

    var form = $(this);
    $.post(form.attr('action'), form.serialize(), trataRespostaDeCadastro);
    iniciaCadastroVisualmente();
  }

  function trataRespostaDeCadastro(dados) {
    if (dados.success) {
      msgSucesso.removeClass('d-none');
      setTimeout(() => location.href = dados.redirectTo, 4000);
    } else {
      encerraCadastroVisualmente();
      exibeErro(dados.erros);
    }
  }

  function exibeErro(erros) {
    let erroKey, erroEl, formItem;

    for (erroKey in erros) {
      formItem = formCadastro.find(`.field[data-attr=${erroKey}]`);
      erroEl = formItem.find('.invalid-feedback');
      formItem.find('.form-control').addClass('is-invalid').select();
      erroEl.text(erros[erroKey][0]).removeClass('d-none');
    }
  }

  function escondeErro() {
    formCadastro.find('.is-invalid').removeClass('is-invalid');
    errosCampos.filter(':not(.d-none)').addClass('d-none').text('');
  }

  function removeErro () {
    $(this).removeClass('is-invalid').next().text('').addClass('d-none');
  }

  function iniciaCadastroVisualmente() {
    travaFormulario();
    marcaSubmitComoCarregando();
    escondeErro();
  }

  function encerraCadastroVisualmente() {
    destravaFormulario();
    desmarcaSubmitComoCarregando();
  }

  function marcaSubmitComoCarregando() {
    submitCadastro.find('span').text('Cadastrando...');
  }

  function desmarcaSubmitComoCarregando() {
    var span = submitCadastro.find('span');
    span.text(span.data('labelOriginal'));
  }

  function travaFormulario() {
    formCadastro.data('processando', true);
    camposCadastro.attr('disabled', true);
  }

  function destravaFormulario() {
    formCadastro.data('processando', false);
    camposCadastro.removeAttr('disabled');
  }

  function processandoCadastro() {
    return formCadastro.data('processando');
  }
});
